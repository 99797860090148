@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/breakpoints";

.slick-dots {
  position: absolute;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 335px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $breakpoint-md) {
      width: 330px;
    }
  
    @media (min-width: $breakpoint-xxl) {
      width: 350px;
    }

    li {
      margin: 0;

      &:not(:last-child) {
        margin-right: 3px;
      }
  
      button {
        background-color: transparent;
        border: none;
        font-size: 0;
        padding: 0;
        width: 6px;
        height: 6px;
        display: flex;
        background: #D9D9D9;
        cursor: pointer;
        opacity: 1;
        -webkit-border-radius: 20px;
        border-radius: 20px;
        -webkit-transition: opacity .3s, background-color .1s, width .3s;
        -o-transition: opacity .3s, background-color .1s, width .3s;
        transition: opacity .3s, background-color .1s, width .3s;
        -webkit-transition-delay: .3s, .1s, 0s;
        -o-transition-delay: .3s, .1s, 0s;
        transition-delay: .3s, .1s, 0s;
      }
  
      &.slick-active {
        button {
          width: 20px;
          background-color: #000;
          color: transparent;
          opacity: 1;
        }
      }
    }

    &.-yellow {
      & .slick-active {
        button {
          background-color: $color-yellow;
        }
      }
    }

    &.-pink {
      & .slick-active {
        button {
          background-color: $color-pink;
        }
      }
    }

    &.-green {
      & .slick-active {
        button {
          background-color: $color-green;
        }
      }
    }

    &.-blue {
      & .slick-active {
        button {
          background-color: $color-blue;
        }
      }
    }
  }
}
