// ------------------ MAIN COLOR PALETTE ------------------ //

$color-primary: #00AAE7;
$color-secondary: #DB00FF;

// ------------------ MISCELLANEOUS COLOR PALETTE ------------------ //

$color-white: #ffffff;
$color-white-dark: #FCFCFF;
$color-black: #000000;

$color-grey: #666666;
$color-grey-dark: #767F9D;

// ------------------ ITEM COLOR PALETTE ------------------ //
$color-pink: #FF3395;
$color-pink-light: #FF65AF;
$color-pink-lighter: #FE97CA;
$color-pink-dark: #9A1555;
$color-pink-shadow: #FF339580;
$color-pink-tag: #FF339533;

$color-yellow: #FFBA33;
$color-yellow-light: #FFCA66;
$color-yellow-lighter: #FDDA98;
$color-yellow-dark: #BD871D;
$color-yellow-shadow: #FFBA3380;
$color-yellow-tag: #FFBA3333;

$color-green: #3DE3A6;
$color-green-light: #55E7B1;
$color-green-lighter: #9DF0D2;
$color-green-dark: #057B50;
$color-green-shadow: #3DE3A680;
$color-green-tag: #3DE3A633;

$color-blue: #00AAE7;
$color-blue-light: #658EFF;
$color-blue-lighter: #A7BEFF;
$color-blue-dark: #004C67;
$color-blue-shadow: #00AAE780;
$color-blue-tag: #00AAE733;