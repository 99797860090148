@import '../../../assets/styles/breakpoints';

.system-list {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 24px;

  &__column {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 24px;
  }
}
