@import '../../../assets/styles/colors';
@import '../../../assets/styles/breakpoints';

.landing-page {
    min-height: var(--doc-height);
    background: url('/assets/images/waves/wave_mobile.svg'), $color-primary;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $breakpoint-md) {
        background: url('/assets/images/waves/wave_tablet.svg'), $color-primary;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media (min-width: $breakpoint-xxl) {
        background: url('/assets/images/waves/wave_desktop.svg'), $color-primary;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__container {
        min-height: var(--doc-height);
        max-width: 330px;

        @media (min-width: $breakpoint-md) {
            max-width: 600px;
        }
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        &-image {
            width: 142px;
        }

        @media (min-width: $breakpoint-md) {
            margin-top: 37px;

            &-image {
                width: 160px;
            }
        }
    }

    &__nohao {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 53px;

        &-image {
            height: 96px;
            width: 126px;
        }

        @media (min-width: $breakpoint-md) {
            &-image {
                height: 134px;
                width: 176px;
            }
        }
    }

    &__main-text {
        color: $color-white;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        margin-top: 67px;

        & p {
            max-width: 300px;
            margin: auto;

            &:first-child {
                font-weight: 300;
            }
        }
        
        @media (min-width: $breakpoint-md) {
            margin-top: 100px;

            & p {
                max-width: 600px;
            }
        }
    }

    &__sub-text {
        color: #fff;
        font-size: 17px;
        font-weight: 300;
        line-height: 22px;
        text-align: center;
        margin: 18px 0 80px 0;

        & p {
            max-width: 300px;
            margin: auto;
        }

        @media (min-width: $breakpoint-md) {
            margin: 18px 0 100px 0;

            & p {
                max-width: 600px;
            }
        }
    }
}