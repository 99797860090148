@font-face {
  font-family: 'Font Awesome 6 Pro Light';
  src: url('../fonts/FontAwesome6Pro-Light.eot');
  src: url('../fonts/FontAwesome6Pro-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/FontAwesome6Pro-Light.woff2') format('woff2'),
      url('../fonts/FontAwesome6Pro-Light.woff') format('woff'),
      url('../fonts/FontAwesome6Pro-Light.ttf') format('truetype'),
      url('../fonts/FontAwesome6Pro-Light.svg#FontAwesome6Pro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Font Awesome 6 Pro Regular';
  src: url('../fonts/FontAwesome6Pro-Regular.eot');
  src: url('../fonts/FontAwesome6Pro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/FontAwesome6Pro-Regular.woff2') format('woff2'),
      url('../fonts/FontAwesome6Pro-Regular.woff') format('woff'),
      url('../fonts/FontAwesome6Pro-Regular.ttf') format('truetype'),
      url('../fonts/FontAwesome6Pro-Regular.svg#FontAwesome6Pro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Font Awesome 6 Pro Solid';
  src: url('../fonts/FontAwesome6Pro-Solid.eot');
  src: url('../fonts/FontAwesome6Pro-Solid.eot?#iefix') format('embedded-opentype'),
      url('../fonts/FontAwesome6Pro-Solid.woff2') format('woff2'),
      url('../fonts/FontAwesome6Pro-Solid.woff') format('woff'),
      url('../fonts/FontAwesome6Pro-Solid.ttf') format('truetype'),
      url('../fonts/FontAwesome6Pro-Solid.svg#FontAwesome6Pro-Solid') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Font Awesome 6 Pro Thin';
  src: url('../fonts/FontAwesome6Pro-Thin.eot');
  src: url('../fonts/FontAwesome6Pro-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/FontAwesome6Pro-Thin.woff2') format('woff2'),
      url('../fonts/FontAwesome6Pro-Thin.woff') format('woff'),
      url('../fonts/FontAwesome6Pro-Thin.ttf') format('truetype'),
      url('../fonts/FontAwesome6Pro-Thin.svg#FontAwesome6Pro-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Font Awesome 6 Brands';
  src: url('../fonts/FontAwesome6Brands-Regular.eot');
  src: url('../fonts/FontAwesome6Brands-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/FontAwesome6Brands-Regular.woff2') format('woff2'),
      url('../fonts/FontAwesome6Brands-Regular.woff') format('woff'),
      url('../fonts/FontAwesome6Brands-Regular.ttf') format('truetype'),
      url('../fonts/FontAwesome6Brands-Regular.svg#FontAwesome6Brands-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Font Awesome 6 Duotone';
  src: url('../fonts/FontAwesome6Duotone-Solid.eot');
  src: url('../fonts/FontAwesome6Duotone-Solid.eot?#iefix') format('embedded-opentype'),
      url('../fonts/FontAwesome6Duotone-Solid.woff2') format('woff2'),
      url('../fonts/FontAwesome6Duotone-Solid.woff') format('woff'),
      url('../fonts/FontAwesome6Duotone-Solid.ttf') format('truetype'),
      url('../fonts/FontAwesome6Duotone-Solid.svg#FontAwesome6Duotone-Solid') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

