@import '../../../assets/styles/colors';
@import '../../../assets/styles/breakpoints';

.roles-page {
  min-height: var(--doc-height);

  &__header {
    height: 239px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: $breakpoint-md) {
      height: 353px;
    }
  }

  &__header-container {
    height: 100%;
    width: 337px;
    margin: auto;

    @media (min-width: $breakpoint-md) {
      width: 694px;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 1240px;
    }
  }

  &__header-title {
    z-index: 100;
    position: relative;
    top: 35px;
    width: 80%;

    & p {
      font-size: 30px;
      font-weight: 600;
      color: $color-white;

      @media (min-width: $breakpoint-xxl) {
        font-size: 35px;
      }
    }

    @media (min-width: $breakpoint-md) {
      top: 95px;
    }
  }

  &__header-layer {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    height: 239px;
    width: 100%;
    background: linear-gradient(73.04deg, rgba(0, 170, 231, 0.8) 19.01%, rgba(219, 0, 255, 0.25) 96.58%), linear-gradient(0deg, rgba(0, 170, 231, 0.5), rgba(0, 170, 231, 0.5));
    
    @media (min-width: $breakpoint-md) {
      height: 353px;
    }
  }

  &__header-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 239px;
    width: 100%;
    object-fit: cover;

    @media (min-width: $breakpoint-md) {
      height: 353px;
    }
  }

  &__content {
    position: absolute;
    top: 150px;
    width: 100vw;
    padding-bottom: 110px;

    @media (min-width: $breakpoint-md) {
      top: 282px;
    }
  }
}