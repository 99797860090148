@import "../../../assets/styles/colors";
@import '../../../assets/styles/breakpoints';

.system-information {
  position: relative;
  width: 100%;

  &__header {
    display: flex;
    justify-content: center;
  }

  &__header-icon {
    position: relative;
    top: -9px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 79px;
    width: 79px;
    border-radius: 8px;
    font-size: 32px;
    z-index: 2;

    @media (min-width: $breakpoint-md) {
      position: absolute;
      right: unset;
      left: 25px;
    }

    @media (min-width: $breakpoint-xxl) {
      position: absolute;
      right: unset;
      left: 25px;
    }

    &:before {
      font-family: "Font Awesome 6 Pro Light";
      content: attr(data-content);
      color: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 79px;
      width: 79px;
    }

    &.-yellow {
      background-color: $color-yellow;
      box-shadow: 0px 3px 15px $color-yellow-shadow;
    }

    &.-pink {
      background-color: $color-pink;
      box-shadow: 0px 3px 15px $color-pink-shadow;
    }

    &.-green {
      background-color: $color-green;
      box-shadow: 0px 3px 15px $color-green-shadow;
    }

    &.-blue {
      background-color: $color-blue;
      box-shadow: 0px 3px 15px $color-blue-shadow;
    }
  }

  &__header-content {
    position: relative;
    left: -20px;
    min-height: 88px;
    width: 299px;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border-radius: 8px;
    padding: 10px 68px;
    filter: drop-shadow(0px 0px 3px #0000001A);

    @media (min-width: $breakpoint-md) {
      padding: 10px 134px;
      width: 694px;
      left: unset;
    }

    @media (min-width: $breakpoint-xxl) {
      padding: 10px 134px;
      width: 1240px;
      left: unset;
    }
  }

  &__header-subtitle {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #767F9D;
    text-transform: uppercase;
  }

  &__header-title {
    font-size: 30px;
    line-height: 35px;
    font-weight: 600;
    color: $color-black;
  }

  &__tags {
    display: block;
    max-width: 335px;
    margin: auto;
    padding: 15px 0 5px 0;
    text-align: center;
    border-bottom: 1px solid #DDDEDF;

    @media (min-width: $breakpoint-md) {
      max-width: 694px;
    }

    @media (min-width: $breakpoint-xxl) {
      max-width: 1240px;
    }
  }

  &__tag {
    display: inline-block;
    background-color: aquamarine;
    padding: 0 10px;
    border-radius: 20px;
    margin-bottom: 10px;

    & p {
      font-size: 12px;
      font-weight: 400;
      line-height: 25px;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.-yellow {
      background-color: $color-yellow-tag;
      color: $color-yellow-dark;
    }

    &.-pink {
      background-color: $color-pink-tag;
      color: $color-pink-dark;
    }

    &.-green {
      background-color: $color-green-tag;
      color: $color-green-dark;
    }

    &.-blue {
      background-color: $color-blue-tag;
      color: $color-blue-dark;
    }
  }
}