@import "../../../assets/styles/colors";
@import "../../../assets/styles/breakpoints";

.system-block-text {
  margin-bottom: 20px;

  @media (min-width: $breakpoint-md) {
    margin-top: 7px;
  }
  
  & h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    letter-spacing: 0em;
    margin-bottom: 10px;
  }

  & h1 {
    font-size: 22px;
  }

  & h2 {
    font-size: 20px;
  }

  & h3 {
    font-size: 18px;
  }

  & h4 {
    font-size: 16px;
  }

  & h5 {
    font-size: 12px;
  }

  & h6 {
    font-size: 12px;
  }

  & p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
  }

  & ul {
    padding-inline-start: 20px;

    & li {
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
    }
  }
}

.system-block-separator {
  height: 5px;

  @media (min-width: $breakpoint-md) {
    display: none;
  }

  &.-yellow {
    background-color: $color-yellow;
    box-shadow: 0px 3px 15px $color-yellow-shadow;
  }

  &.-pink {
    background-color: $color-pink;
    box-shadow: 0px 3px 15px $color-pink-shadow;
  }

  &.-green {
    background-color: $color-green;
    box-shadow: 0px 3px 15px $color-green-shadow;
  }

  &.-blue {
    background-color: $color-blue;
    box-shadow: 0px 3px 15px $color-blue-shadow;
  }
}