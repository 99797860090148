@import '../../../assets/styles/breakpoints';

.role-list {
  display: grid;
  grid-template-columns: 160px 160px;
  grid-gap: 15px;
  grid-auto-flow: row;
  justify-content: center;

  @media (min-width: $breakpoint-md) {
    grid-template-columns: 210px 210px 210px;
    grid-gap: 17px;
  }

  @media (min-width: $breakpoint-xxl) {
    grid-template-columns: 260px 260px 260px 260px;
    grid-gap: 25px;
  }
}