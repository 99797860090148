@import "../../../assets/styles/colors";
@import '../../../assets/styles/breakpoints';

.system-list-item {
  background-color: #fff;
  border-radius: 10px;
  height: 80px;
  width: 335px;
  box-shadow: 0px -1px 7px 0px rgba(0,0,0,0.1);
  cursor: pointer;

  @media (min-width: $breakpoint-xxl) {
    height: 94px;
    width: 394px;
  }

  &.-open {
    height: auto;

    & .system-list-item__arrow {
      &:before {
        transform: rotate(180deg);
      }
    }

    & .system-list-item__body {
      border-top: 1px solid #e2e2e2;
      padding: 15px 25px;
      transform: scaleY(1);
      height: auto;
      opacity: 1;
      transition:
        all 0.3s,
        padding 0s;
    }
  }

  &__header {
    height: 80px;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: $breakpoint-xxl) {
      height: 94px;
      padding: 17px 29px;
    }

    & .system-list-item__left {
      display: flex;
      align-items: center;

      & .system-list-item__icon {
        height: 50px;
        width: 50px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
  
        &:before {
          font-family: "Font Awesome 6 Pro Light";
          font-size: 20px;
          content: attr(data-content);
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          width: 50px;
        }

        &.yellow {
          background-color: $color-yellow;
          box-shadow: 0px 3px 15px $color-yellow-shadow;
        }

        &.pink {
          background-color: $color-pink;
          box-shadow: 0px 3px 15px $color-pink-shadow;
        }

        &.green {
          background-color: $color-green;
          box-shadow: 0px 3px 15px $color-green-shadow;
        }

        &.blue {
          background-color: $color-blue;
          box-shadow: 0px 3px 15px $color-blue-shadow;
        }
      }
  
      & .system-list-item__title {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin-left: 13px;
      }
    }

    & .system-list-item__arrow {
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        font-family: "Font Awesome 6 Pro Solid";
        font-size: 20px;
        content: '\f063';
        color: #666666;
        transition: all 0.3s ease-in-out;
        margin-left: 10px;

        @media (min-width: $breakpoint-xxl) {
          font-size: 25px;
        }
      }
    }
  }

  &__body {
    padding-top: 0;
    padding-bottom: 0;
    transform: scaleY(0);
    transform-origin: top;
    height: 0;
    opacity: 0;
    transition: 0s;

    & .system-list-item__text {
      margin-bottom: 25px;
    }

    & .system-list-item__buttons {
      display: flex;
      justify-content: space-around;

      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }
}