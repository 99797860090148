@import '../../../assets/styles/breakpoints';

.system-page {
  min-height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &__header {
    position: relative;
    height: 239px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);

    @media (min-width: $breakpoint-md) {
      height: 353px;
    }
  }

  &__header-container {
    height: 100%;
    width: 337px;
    margin: auto;

    @media (min-width: $breakpoint-md) {
      width: 694px;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 1240px;
    }
  }

  &__header-layer {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    background: #00000030;
    height: 100%;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      height: 353px;
    }
  }

  &__header-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 239px;
    width: 100%;
    object-fit: cover;

    @media (min-width: $breakpoint-md) {
      height: 353px;
    }
  }

  &__content {
    position: absolute;
    top: 170px;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      top: 282px;
    }
  }

  &__content-container {
    height: 100%;
    margin: auto;

    @media (min-width: $breakpoint-md) {
      width: 694px;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 1240px;
    }
  }
}