@import '../../../assets/styles/breakpoints';

.system-block-list {
  display: grid;
  grid-template-columns: 335px;
  grid-gap: 24px;
  grid-auto-flow: row;
  justify-content: center;

  width: 335px;
  margin: 20px auto;

  @media (min-width: $breakpoint-md) {
    width: 694px;
    grid-template-columns: 330px 330px;
    grid-gap: 34px;
  }

  @media (min-width: $breakpoint-xxl) {
    width: 734px;
    grid-template-columns: 350px 350px;
  }
}