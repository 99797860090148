@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import './colors';
@import './fonts';
@import './fontawesome';

:root {
  --doc-height: 100%;
}
  
* {
  outline: none;

  font-family: $font-poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  line-height: 120%;
}
  
html {
  overflow-x: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: unset;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}