@import "../../../assets/styles/colors";
@import "../../../assets/styles/breakpoints";

.system-block-video {
  margin-bottom: 20px;
  
  &__title {
    & h1 {
      font-size: 25px;
      font-weight: 500;
      line-height: 38px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px -1px 7px 0px rgba(0,0,0,0.1);
    
    &.-first-play {
      & .system-block-video__layer {
        &:after {
          display: flex;
        }
      }

      & .system-block-video__video {
        background-color: rgba(255, 255, 255, 0.2);
      }

      & .system-block-video__controls {
        display: none;
      }
    }

    &.-full-screen {
      @media (orientation: landscape) {
        & .system-block-video__video {
          max-height: unset !important;
          height: 100% !important;
          border-radius: 0 !important;
      
          @media (min-width: $breakpoint-md) {
            max-height: unset !important;
            height: 100% !important;
          }
      
          @media (min-width: $breakpoint-xxl) {
            max-height: unset !important;
            height: 100% !important;
          }
        }
      }
      
      @media (orientation: portrait) {
        & .system-block-video__video {
          max-height: unset !important;
          width: 100% !important;
          border-radius: 0 !important;
      
          @media (min-width: $breakpoint-md) {
            max-height: unset !important;
            width: 100% !important;
          }
      
          @media (min-width: $breakpoint-xxl) {
            max-height: unset !important;
            width: 100% !important;
          }
        }
      }

      & .system-block-video__controls {
        max-width: unset !important;

        & .system-block-video__controls-play-button,
        & .system-block-video__controls-mute-button,
        & .system-block-video__controls-full-screen-button {
          height: 40px;
        }

        & .system-block-video__controls-play-button::after,
        & .system-block-video__controls-mute-button::after,
        & .system-block-video__controls-full-screen-button::after {
          font-size: 30px;
        }
      }
    }

    &:hover {
      .system-block-video__controls {
        bottom: 0;
      }
        
    }

    &.-yellow {
      & .system-block-video__layer {
        &:after {
          background-color: $color-yellow;
          box-shadow: 0px 3px 15px $color-yellow-shadow;
        }
      }

      & .system-block-video__controls-play-button {
        &.-play::after, 
        &.-pause::after {
          color: $color-yellow;
        }
      }

      & .system-block-video__controls-mute-button {
        &.-mute::after,
        &.-unmute::after {
          color: $color-yellow;
        }
      }

      & .system-block-video__controls-full-screen-button {
        &:after {
          color: $color-yellow;
        }
      }

      & .system-block-video__controls-progress {
        &:-webkit-slider-thumb,
        &:-moz-range-thumb,
        &:-ms-thumb {
          background-color: $color-yellow;
        }
      }

      input[type=range]::-webkit-slider-thumb {
        background-color: $color-yellow;
      }
      
      input[type=range]::-moz-range-thumb {
        background-color: $color-yellow;
      }
      
      input[type=range]::-ms-thumb {
        background-color: $color-yellow;
      }
    }

    &.-pink {
      & .system-block-video__layer {
        &:after {
          background-color: $color-pink;
          box-shadow: 0px 3px 15px $color-pink-shadow;
        }
      }

      & .system-block-video__controls-play-button {
        &.-play::after, 
        &.-pause::after {
          color: $color-pink;
        }
      }

      & .system-block-video__controls-mute-button {
        &.-mute::after,
        &.-unmute::after {
          color: $color-pink;
        }
      }

      & .system-block-video__controls-full-screen-button {
        &:after {
          color: $color-pink;
        }
      }

      & .system-block-video__controls-progress {
        &:-webkit-slider-thumb,
        &:-moz-range-thumb,
        &:-ms-thumb {
          background-color: $color-pink;
        }
      }

      input[type=range]::-webkit-slider-thumb {
        background-color: $color-pink;
      }
      
      input[type=range]::-moz-range-thumb {
        background-color: $color-pink;
      }
      
      input[type=range]::-ms-thumb {
        background-color: $color-pink;
      }
    }

    &.-green {
      & .system-block-video__layer {
        &:after {
          background-color: $color-green;
          box-shadow: 0px 3px 15px $color-green-shadow;
        }
      }

      & .system-block-video__controls-play-button {
        &.-play::after, 
        &.-pause::after {
          color: $color-green;
        }
      }

      & .system-block-video__controls-mute-button {
        &.-mute::after,
        &.-unmute::after {
          color: $color-green;
        }
      }

      & .system-block-video__controls-full-screen-button {
        &:after {
          color: $color-green;
        }
      }

      & .system-block-video__controls-progress {
        &:-webkit-slider-thumb,
        &:-moz-range-thumb,
        &:-ms-thumb {
          background-color: $color-green;
        }
      }

      input[type=range]::-webkit-slider-thumb {
        background-color: $color-green;
      }
      
      input[type=range]::-moz-range-thumb {
        background-color: $color-green;
      }
      
      input[type=range]::-ms-thumb {
        background-color: $color-green;
      }
    }

    &.-blue {
      & .system-block-video__layer {
        &:after {
          background-color: $color-blue;
          box-shadow: 0px 3px 15px $color-blue-shadow;
        }
      }

      & .system-block-video__controls-play-button {
        &.-play::after, 
        &.-pause::after {
          color: $color-blue;
        }
      }

      & .system-block-video__controls-mute-button {
        &.-mute::after,
        &.-unmute::after {
          color: $color-blue;
        }
      }

      & .system-block-video__controls-full-screen-button {
        &:after {
          color: $color-blue;
        }
      }

      & .system-block-video__controls-progress {
        &:-webkit-slider-thumb,
        &:-moz-range-thumb,
        &:-ms-thumb {
          background-color: $color-blue;
        }
      }

      input[type=range]::-webkit-slider-thumb {
        background-color: $color-blue;
      }
      
      input[type=range]::-moz-range-thumb {
        background-color: $color-blue;
      }
      
      input[type=range]::-ms-thumb {
        background-color: $color-blue;
      }
    }
  }

  &__layer {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      font-family: "Font Awesome 6 Pro Solid";
      content: 'play';
      display: none;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      border-radius: 8px;
      background-color: $color-black;
      color: $color-white;
      font-size: 20px;
      font-weight: 900;
      line-height: 20px;
      letter-spacing: 0em;
    }

    &.-hide {
      background-color: unset;
    }
  }

  &__video {
    width: 100%;
    max-height: 189px;
    border-radius: 8px;

    @media (min-width: $breakpoint-md) {
      max-height: 186px;
    }

    @media (min-width: $breakpoint-xxl) {
      max-height: 197px;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    bottom: -20%;
    padding: 8px 12px;
    width: 100%;
    max-width: 500px;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    transition: all 0.3s ease-in-out;
    z-index: 3;

    &.-shown {
      bottom: 0;
    }
  }

  &__controls-play-button,
  &__controls-mute-button,
  &__controls-full-screen-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__controls-play-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    height: 20px;
    width: 10%;
    &.-play::after {
      font-family: "Font Awesome 6 Pro Regular";
      content: 'play';
      color: $color-white;
    }

    &.-pause::after {
      font-family: "Font Awesome 6 Pro Regular";
      content: 'pause';
      color: $color-white;
    }
  }

  &__controls-mute-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    height: 20px;
    width: 10%;
  
    &.-mute::after {
      font-family: "Font Awesome 6 Pro Regular";
      content: '\f6a8';
      color: $color-white;
    }
  
    &.-unmute::after {
      font-family: "Font Awesome 6 Pro Regular";
      content: '\f6a9';
      color: $color-white;
    }
  }

  &__controls-full-screen-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    height: 20px;
    width: 10%;
  
    &:after {
      font-family: "Font Awesome 6 Pro Regular";
      content: '\f065';
      color: $color-white;
    }
  }

  &__controls-progress {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    width: 60%;
    height: 7px;
    background-color: $color-white;
    border-radius: 8px;
    outline: none;
  }
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 7px;
  cursor: pointer;
  width: 60%;
}

input[type=range]::-moz-track {
  -moz-appearance: none;
  height: 7px;
  cursor: pointer;
  width: 60%;
}

input[type=range]::-ms-track {
  appearance: none;
  height: 7px;
  cursor: pointer;
  width: 60%;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background-color: $color-white;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -5px;
  border: none;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background-color: $color-white;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -5px;
  border: none;
}

input[type=range]::-ms-thumb {
  appearance: none;
  height: 16px;
  width: 16px;
  background-color: $color-white;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -5px;
  border: none;
}

.system-block-separator {
  height: 5px;

  @media (min-width: $breakpoint-md) {
    display: none;
  }

  &.-yellow {
    background-color: $color-yellow;
    box-shadow: 0px 3px 15px $color-yellow-shadow;
  }

  &.-pink {
    background-color: $color-pink;
    box-shadow: 0px 3px 15px $color-pink-shadow;
  }

  &.-green {
    background-color: $color-green;
    box-shadow: 0px 3px 15px $color-green-shadow;
  }

  &.-blue {
    background-color: $color-blue;
    box-shadow: 0px 3px 15px $color-blue-shadow;
  }
}