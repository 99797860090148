@import "../../../assets/styles/colors";
@import '../../../assets/styles/breakpoints';

.system-block-image {
  margin-bottom: 50px;
  
  &__title {
    & h1 {
      font-size: 25px;
      font-weight: 500;
      line-height: 38px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  &__slider {
    height: 335px;
    width: 335px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px -1px 7px 0px rgba(0,0,0,0.1);

    @media (min-width: $breakpoint-md) {
      height: 330px;
      width: 330px;
    }

    @media (min-width: $breakpoint-xxl) {
      height: 350px;
      width: 350px;
    }

    & .slider-settings {
      height: 100%;
  
      .slick-list {
        height: 100%;
      }
  
      .slick-track {
        display: flex;
        height: 100%;
      }
  
      .slick-slide {
        height: 100%;
        cursor: pointer;
  
        div {
          height: 100%;
        }
      }
    }
  }

  &__slider-container {
    overflow: hidden;
  }

  &__slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.system-block-separator {
  height: 5px;

  @media (min-width: $breakpoint-md) {
    display: none;
  }

  &.-yellow {
    background-color: $color-yellow;
    box-shadow: 0px 3px 15px $color-yellow-shadow;
  }

  &.-pink {
    background-color: $color-pink;
    box-shadow: 0px 3px 15px $color-pink-shadow;
  }

  &.-green {
    background-color: $color-green;
    box-shadow: 0px 3px 15px $color-green-shadow;
  }

  &.-blue {
    background-color: $color-blue;
    box-shadow: 0px 3px 15px $color-blue-shadow;
  }
}