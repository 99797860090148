@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/breakpoints';

.back-button {
  position: relative;
  top: 28px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }

  &:after {
    font-family: "Font Awesome 6 Pro Regular";
    content: "\f060";
    font-size: 20px;
    color: $color-white;

    @media (min-width: $breakpoint-xxl) {
      font-size: 28px;
    }
  }

  @media (min-width: $breakpoint-md) {
    top: 80px;
  }
}