.button-system {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  gap: 10px;

  height: 39px;
  width: 112px;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 170, 231, 0.032) 50.31%, rgba(255, 255, 255, 0) 100%), linear-gradient(360deg, rgba(0, 170, 231, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;

  border: 1.5px solid #00AAE7;
  box-shadow: 0px 0px 8px rgba(0, 170, 231, 0.16);
  border-radius: 10px;

  font-weight: 600;
  
  &:hover {
    cursor: pointer;
  }

  &:after {
    font-family: "Font Awesome 6 Pro Regular";
    content: "\f08e";
  }
}