@import '../../../assets/styles/colors';
@import '../../../assets/styles/breakpoints';

.role-page {
  min-height: 100vh;

  &__header {
    position: relative;
    height: 239px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);

    @media (min-width: $breakpoint-md) {
      height: 353px;
    }
  }

  &__header-container {
    height: 100%;
    width: 337px;
    margin: auto;

    @media (min-width: $breakpoint-md) {
      width: 694px;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 1240px;
    }
  }

  &__header-title {
    z-index: 100;
    position: relative;
    top: 50px;
    padding-bottom: 10px;
    border-bottom: 2px solid $color-white;

    @media (min-width: $breakpoint-md) {
      top: 95px;
      width: 50%;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 30%;
    }

    & p {
      font-size: 30px;
      font-weight: 600;
      color: $color-white;
      width: 80%;

      @media (min-width: $breakpoint-md) {
        width: 100%;
      }
      
      @media (min-width: $breakpoint-xxl) {
        font-size: 35px;
      }
    }

  }

  &__header-layer {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    background: #00000030;
    height: 100%;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      height: 353px;
    }
  }

  &__header-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 239px;
    width: 100%;
    object-fit: cover;

    @media (min-width: $breakpoint-md) {
      height: 353px;
    }
  }

  &__content {
    position: absolute;
    top: 180px;
    width: 100vw;
    display: flex;
    justify-content: center;
    
    @media (min-width: $breakpoint-md) {
      top: 282px;
    }
  }
}