@import "../../../assets/styles/colors";
@import "../../../assets/styles/breakpoints";

.role-list-item {
  height: 120px;
  width: 160px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px;
  position: relative;
  cursor: pointer;

  @media (min-width: $breakpoint-md) {
    height: 158px;
    width: 210px;
    padding: 20px 26px;
  }

  @media (min-width: $breakpoint-xxl) {
    height: 195px;
    width: 260px;
    padding: 25px 33px;
  }

  &__folder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: drop-shadow(0px 0px 3px #0000001A);

    @media (min-width: $breakpoint-md) {
      height: 158px;
      width: 210px;
    }

    @media (min-width: $breakpoint-xxl) {
      height: 195px;
      width: 260px;
    }
  }

  &__icon{
    z-index: 1;

    &:before {
      font-family: "Font Awesome 6 Pro Light";
      content: attr(data-content);
      font-size: 25px;
      line-height: 30px;
      color: $color-black;

      @media (min-width: $breakpoint-md) {
        font-size: 35px;
        line-height: 40px;
      }

      @media (min-width: $breakpoint-xxl) {
        font-size: 40px;
        line-height: 45px;
      }
    }

    &.-yellow {
      &:before {
        color: $color-yellow;
        filter: drop-shadow(0px 0px 5px $color-yellow-shadow);
      }
    }

    &.-pink {
      &:before {
        color: $color-pink;
        filter: drop-shadow(0px 0px 5px $color-pink-shadow);
      }
    }

    &.-green {
      &:before {
        color: $color-green;
        filter: drop-shadow(0px 0px 5px $color-green-shadow);
      }
    }

    &.-blue {
      &:before {
        color: $color-blue;
        filter: drop-shadow(0px 0px 5px $color-blue-shadow);
      }
    }
  }

  &__title {
    z-index: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    @media (min-width: $breakpoint-md) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}